import axiosInstance from '@/plugins/axios'

export default {

  getTicketFile(ticketId, fileName) {
    const url = `admin/ticket/${ticketId}/file/${fileName}`

    return axiosInstance.get(url, {
      responseType: 'arraybuffer',
    })
      .then(response => Buffer.from(response.data, 'binary').toString('base64'))
  },

  getTicketResponseFile(ticketId, originalName, fileName, mimeType) {
    const url = `admin/ticket/${ticketId}/response-file/${fileName}`

    return axiosInstance.get(url, {
      responseType: mimeType === 'application/pdf' ? 'blob' : 'arraybuffer',
    })
      .then(response => {
        if (mimeType === 'application/pdf') {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${originalName}.pdf`)
          document.body.appendChild(link)
          link.click()
        } else {
          return Buffer.from(response.data, 'binary').toString('base64')
        }
      })
  },

  answerTicket(ticketId, response, files, state) {
    const url = `admin/ticket/${ticketId}`

    const formData = new FormData()
    formData.append('response', response)
    formData.append('state', state)
    files.forEach((f, index) => {
      formData.append(`file-${index}`, f.file)
    })

    return axiosInstance.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
