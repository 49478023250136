<template>
  <div v-if="data">
    <b-overlay
      :show="status === 'loading'"
      variant="transparent"
      blur="2px"
      rounded="sm"
    >
      <b-table
        :items="data"
        :fields="tableFields"
        striped
        responsive
        small
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :busy="status === 'initial'"
        no-local-sorting
        :style="data.length < 8 ? 'padding-bottom: 40rem' : ''"
        @sort-changed="onSortingChanged"
      >
        <template #table-busy>
          <table-loading />
        </template>
        <template
          v-for="(_, slotName) of $scopedSlots"
          v-slot:[slotName]="scope"
        >
          <slot
            :name="slotName"
            v-bind="scope"
          />
        </template>
        <template #cell(actions)="data">
          <action-list
            :data="data.item"
            :items="rowActions"
            @remove="onRowDelete(data.item.id)"
            v-on="$listeners"
          />
        </template>
      </b-table>
    </b-overlay>
    <b-row class="justify-content-between">
      <b-col>
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
            @input="onPerPageChange"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="metaData.totalCount"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
          @input="onPageChange"
        />
      </b-col>
      <b-col class="text-right">
        Result {{ perPage*(currentPage-1)+1 }} - {{ Math.min(perPage*(currentPage), metaData.totalCount) }} of total {{ metaData.totalCount }} rows
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable, BRow, BCol, BFormGroup, BFormSelect, BPagination, BOverlay,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import { debounce } from 'lodash'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import TableLoading from '@/components/ui/tables/TableLoading.vue'
import ActionList from '@/components/ui/ActionList.vue'

const pageOptions = [10, 25, 50, 100]

export default {
  components: {
    BTable,
    TableLoading,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BOverlay,
    ActionList,
  },
  props: {
    moduleName: String,
    slots: Array,
    fields: Array,
    filters: Object,
    sortBy: {
      type: String,
      default: null,
      required: false,
    },
    sortDesc: {
      type: Boolean,
      default: false,
      required: false,
    },
    actions: {
      default: null,
      type: Array,
      required: false,
    },
    deletable: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      pageOptions,
    }
  },
  computed: {
    tableFields() {
      return this.actions ? [...this.fields, {
        key: 'actions',
        sortable: false,
      }] : this.fields
    },
    rowActions() {
      const { actions } = this
      if (this.deletable) {
        const action = actions.find(item => item.emit === 'remove')
        if (action) return actions
        actions.push({
          text: 'Remove row',
          icon: 'Trash2Icon',
          emit: 'remove',
        })
      }
      return actions
    },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      // eslint-disable-next-line func-names
      handler: debounce(function () {
        this.onFilterChange()
      }, 500),
    },
  },
  created() {
    store.dispatch(`${this.moduleName}/setSort`, { column: this.sortBy, dir: this.sortDesc ? 'DESC' : 'ASC' })
    store.dispatch(`${this.moduleName}/setFilters`, this.filters)
    store.dispatch(`${this.moduleName}/loadAll`)
  },
  beforeCreate() {
    const { moduleName } = this.$options.propsData
    if (!this.$options.computed) {
      this.$options.computed = {}
    }
    Object.assign(this.$options.computed, {
      ...get(moduleName, ['data', 'status', 'metaData', 'limit', 'sort']),
    })
  },
  mounted() {
    this.perPage = this.limit
    this.currentPage = this.metaData.page
    this.reloadAll()
  },
  methods: {
    reloadAll() {
      store.dispatch(`${this.moduleName}/reloadAll`)
    },
    onPageChange() {
      store.dispatch(`${this.moduleName}/changePage`, { page: this.currentPage })
    },
    onPerPageChange() {
      store.dispatch(`${this.moduleName}/changeLimit`, { limit: this.perPage })
    },
    onSortingChanged(ctx) {
      if (ctx.sortBy) {
        store.dispatch(`${this.moduleName}/changeSort`, { column: ctx.sortBy, dir: ctx.sortDesc ? 'DESC' : 'ASC' })
      }
    },
    onFilterChange() {
      store.dispatch(`${this.moduleName}/changeFilters`, this.filters)
    },
    onRowDelete(id) {
      Swal.fire({
        title: 'Remove this row',
        text: 'Are you sure you want to remove this row?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch(`${this.moduleName}/delete`, id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Remove successful',
                  icon: 'TrashIcon',
                  text: 'Row was successfully removed',
                  variant: 'success',
                },
              })
            })
        }
      })
    },
  },
}
</script>
