<template>
  <div>
    <b-card>
      <b-row class="align-items-center">
        <b-col
          lg="3"
          class="mb-2"
        >
          <h3>Ticket number or email:</h3>
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="advancedFilter.search"
                type="search"
                placeholder="Search by ticket number or email"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!advancedFilter.search"
                  @click="advancedFilter.search = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <h3>States:</h3>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="advancedFilter.state"
            name="flavour-1"
            class="d-flex flex-wrap"
          >
            <b-badge
              v-for="(value, key, index) in stateVariants"
              :key="index"
              :variant="`light-${value}`"
              class="mr-1 mt-1"
            >
              <b-form-checkbox
                :value="key"
                class="vh-center"
              >
                <span :class="`text-${value}`">{{ key }}</span>
              </b-form-checkbox>
            </b-badge>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card>

    <data-table
      ref="ticketTable"
      :module-name="'ticketsList'"
      :fields="fields"
      :filters="advancedFilter"
      :actions="actions"
      sort-by="updatedAt"
      :sort-desc="true"
      deletable
      @edit="onEdit"
      @hide="onHide"
    >

      <template #cell(ticket_number)="data">
        <b-link @click.prevent="onEdit(data.item)">
          {{ data.item.ticket_number }}
        </b-link>
        <b-badge
          v-if="data.item.hide"
          variant="light-danger"
        >
          Ticket is hidden to user
        </b-badge>
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}<br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="data.item.user.kyc_at ? [ `Verified ${formatDateTime(data.item.user.kyc_at)}`] : []"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(updatedAt)="data">
        {{ formatDateTime(data.item.updatedAt) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${stateVariants[data.item.state]}`">
          {{ data.item.state }}
        </b-badge>
      </template>

      <template #cell(resolver)="data">
        {{ data.item.resolver.firstName }} {{ data.item.resolver.lastName }}<br>
        <small>{{ data.item.resolver.email }}</small>
      </template>

    </data-table>

    <b-modal
      id="ticketModal"
      title="Edit ticket"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <ticket-edit
        :ticket="ticketEdit"
        @close="onCloseModal"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BFormCheckboxGroup,
  BButton,
  BCol,
  BRow,
  BBadge,
  BCard,
  BLink,
  BFormCheckbox,
  BModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDateTime, formatCurrency, formatDate } from '@/plugins/formaters'
import table from '@/mixins/table'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DataTable from '@/components/ui/tables/DataTable.vue'
import TagsList from '@/components/page/TagsList.vue'
import TicketEdit from '@/views/ticket/components/TicketEdit.vue'
import store from '@/store'

const actions = [
  {
    text: 'Edit/Show',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Hide/Show to user',
    icon: 'EyeOffIcon',
    emit: 'hide',
  },
]

const fields = [
  {
    key: 'ticket_number',
    sortable: true,
  },
  {
    key: 'user',
    sortable: true,
  },
  {
    key: 'subject',
    sortable: false,
  },
  {
    key: 'state',
    sortable: true,
  },
  {
    key: 'createdAt',
    sortable: true,
  },
  {
    key: 'updatedAt',
    sortable: true,
  },
  {
    key: 'ip',
    sortable: true,
  },
  {
    key: 'resolver',
    label: 'resolved by',
    sortable: true,
  },
  {
    key: 'actions',
    sortable: false,
  },
]

export default {
  components: {
    TicketEdit,
    DataTable,
    TagsList,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BButton,
    BCol,
    BRow,
    BBadge,
    BCard,
    BLink,
    BModal,
  },
  mixins: [table],
  data() {
    return {
      fields,
      advancedFilter: {
        states: [],
        search: '',
      },
      stateVariants: {
        pending: 'danger',
        completed: 'success',
      },
      actions,
      accountEdit: null,
      consentEdit: null,
      tagsToAccountsLoading: false,
      ticketEdit: null,
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    formatDate,
    onEdit(item) {
      this.ticketEdit = item
      this.$bvModal.show('ticketModal')
    },
    onCloseModal() {
      this.$bvModal.hide('ticketModal')
      this.$refs.ticketTable.reloadAll()
    },
    onHide(ticket) {
      store.dispatch('ticketsList/update', { id: ticket.id, data: { hide: !ticket.hide } }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            text: 'Entity edit was successful',
            variant: 'success',
          },
        })
        this.$store.dispatch('ticketsList/reloadAll')
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CheckCircleIcon',
            text: 'An error occurred during entity edit',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
