<template>
  <b-overlay :show="loading">
    <vue-gallery
      id="user-gallery"
      :images="files"
      :index="index"
      @close="index = null"
    />
    <b-row>
      <div
        v-for="(image, imageIndex, key) in files"
        :key="key"
      >
        <b-img
          :height="imgHeight"
          :width="imgHeight"
          :src="image"
          class="m-1"
          @click="index = imageIndex"
        />
      </div>
    </b-row>
  </b-overlay>
</template>
<script>
import {
  BRow, BImg, BOverlay,
} from 'bootstrap-vue'
import VueGallery from 'vue-gallery'
import { formatDateTime } from '@/plugins/formaters'
import TicketAPI from '@/api/ticket'

export default {
  components: {
    VueGallery,
    BRow,
    BImg,
    BOverlay,
  },
  props: {
    ticketFiles: [Array, Object],
    ticketId: String,
    imgHeight: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      files: [],
      index: null,
      loading: false,
    }
  },
  watch: {
    ticketFiles: {
      immediate: true,
      deep: true,
      handler() {
        this.loadData()
      },
    },
  },
  methods: {
    formatDateTime,
    async loadData() {
      this.loading = true
      if (this.ticketFiles.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const file of this.ticketFiles) {
          TicketAPI.getTicketFile(this.ticketId, file.file_name).then(f => this.files.push('data:image/jpg;base64,'.concat(f)))
        }
      }
      this.loading = false
    },
  },
}
</script>
