<template>
  <b-overlay :show="loading">
    <b-card>
      <!--    Header -->
      <div class="d-flex justify-content-between">
        <h3>
          {{ ticket.subject }}
        </h3>
        <p class="text-primary ml-auto mb-25">
          Ticked ID: {{ ticket.ticket_number }}
        </p>
      </div>
      <!--    Content -->
      <div>
        <p class="p-1">
          {{ ticket.content }}
        </p>
        <user-files
          v-if="ticket.files !== null && ticket.files_deleted_at === null"
          :ticket-files="ticket.files"
          :ticket-id="ticket.id"
        />
      </div>
      <hr>
      <b-form-group>
        <label>Answer</label>
        <b-form-textarea
          v-model="response"
          rows="6"
          max-rows="12"
          class="custom-scroll-bar overflow-auto"
        />
      </b-form-group>

      <file-pond
        ref="filepond"
        class="filepond-ticket-attachments"
        name="ticket-attachments"
        accepted-file-types="image/jpeg, image/jpg, image/png, application/pdf"
        label-idle="<div class='d-flex flex-column'><div class='d-flex flex-column flex-sm-row flex-lg-column flex-xl-row text-header'><span>Select file to upload </span> <span>or drag and drop it here</span></div><span class='text-placeholder font-small-3 d-none d-sm-block d-lg-none d-xl-block'>(.jpg, .png, .webp) Max file size: 5MB</span></div>"
        :allow-multiple="true"
        :credits="''"
        data-max-file-size="5MB"
        :required="true"
      />

      <div v-if="ticket.response_files">
        <div
          v-for="(file, index) in ticket.response_files"
          :key="index"
          class="d-flex p-75 mt-1"
          :class="`bg-${skin==='dark'?'dark':'light'}`"
        >
          <div>
            <feather-icon
              :icon="`${file.mime === 'application/pdf'?'FileTextIcon':'ImageIcon'}`"
              size="20"
              class="mr-50 text-primary"
            />
          </div>
          <p class="mb-0 text-header font-weight-light font-small-3">
            {{ file.original_name }}
          </p>
          <b-button
            variant="unstyled"
            class="p-0 ml-auto"
            :disabled="!!ticket.response_files_deleted_at"
            @click="getResponseFile(ticket.id, file.file_name, file.mime, file.original_name)"
          >
            <feather-icon
              icon="SearchIcon"
              size="18"
              class="text-header"
            />
          </b-button>
        </div>
      </div>

      <b-card-footer class="d-flex justify-content-between mt-2">
        <b-button
          variant="info"
          @click="onSave"
        >
          Save & keep ticket open
        </b-button>
        <b-button
          class="mx-1"
          variant="secondary"
          @click="onSaveNotRelated"
        >
          Save as not related ticket
        </b-button>
        <b-button
          variant="success"
          @click="onSaveAndClose"
        >
          Save & set ticket as completed
        </b-button>
      </b-card-footer>
      <vue-easy-lightbox
        scroll-disabled
        :visible="lightbox"
        :imgs="img"
        @hide="onHideLightbox"
      >
        <template v-slot:toolbar="{}">
          <div class="d-none">
            x
          </div>
        </template>
      </vue-easy-lightbox>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BFormTextarea, BFormGroup, BCardFooter, BButton, BOverlay,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import vueFilePond from 'vue-filepond'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import VueEasyLightbox from 'vue-easy-lightbox'
import UserFiles from '@/views/ticket/components/UserFiles.vue'
import TicketAPI from '@/api/ticket'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
)

export default {
  components: {
    UserFiles,
    BCard,
    BFormTextarea,
    BFormGroup,
    FilePond,
    BCardFooter,
    BButton,
    BOverlay,
    VueEasyLightbox,
  },
  props: {
    ticket: Object,
  },
  data() {
    return {
      response: this.ticket.response === 'null' ? '' : this.ticket.response,
      responseFiles: null,
      lightbox: false,
      img: null,
      loading: false,
      notRelatedTopic: 'The ticket that you sent was not related to a bug or technical issue in the dashboard. \n'
          + '\n'
          + 'Due to this, the ticket was not processed.\n'
          + '\n'
          + 'Thank you for understanding.',
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    ...get('app', ['isMobile', 'currentBreakPoint']),
  },
  methods: {
    onSave() {
      const files = this.$refs.filepond.getFiles()

      this.loading = true
      TicketAPI.answerTicket(this.ticket.id, this.response, files, 'pending').then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ticket was updated.',
            icon: 'FileIcon',
            variant: 'success',
          },
        })
        this.$emit('close')
      }).finally(() => {
        this.loading = false
      })
    },
    onSaveNotRelated() {
      this.response = this.notRelatedTopic
      this.onSaveAndClose()
    },
    onSaveAndClose() {
      const files = this.$refs.filepond.getFiles()

      this.loading = true
      TicketAPI.answerTicket(this.ticket.id, this.response, files, 'completed').then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ticket was updated.',
            icon: 'FileIcon',
            variant: 'success',
          },
        })
        this.$emit('close')
      }).finally(() => {
        this.loading = false
      })
    },
    getResponseFile(id, fileName, mime, originalName) {
      this.showLoader(true, true)
      TicketAPI.getTicketResponseFile(id, originalName, fileName, mime).then(response => {
        if (mime !== 'application/pdf') {
          this.img = 'data:image/jpg;base64,'.concat(response)
          this.lightbox = true
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to open file.',
            icon: 'FileIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.showLoader(false)
      })
    },
    onHideLightbox() {
      this.lightbox = false
    },
  },
}
</script>
<style scoped lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

.filepond-ticket-attachments::v-deep {
  .filepond--root {
    margin-bottom: 8px;
    transition: all 150ms ease-in-out;
    border: 1px solid $info;
    border-radius: 7px;

    &:hover {
      background-color: rgba($primary, 12%);
      border: 1px solid $primary;
      box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    }
  }

  .filepond--panel-top, .filepond--panel-bottom, .filepond--browser {
    background-color: transparent !important;
    opacity: 20 !important;
  }

  .filepond--panel-root {
    background-color: transparent;
  }
}
</style>
